<template>
  <b-card>
      <!-- Form -->
      <b-form
        class="p-2"
      >
        <!-- <b-row>
          <b-col
            xl="6"
          >
            <b-form-group
              label="Nama FAQ"
              label-for="nama"
            >
              <b-form-input
                id="nama"
                v-model="rowData.data.nama"
                autofocus
                trim
                placeholder=""
              />
            </b-form-group>

          </b-col>
        </b-row> -->

        <b-row>
          <b-col
            xl="8"
          >
            <b-form-group
              label="Kategori Keterampilan"
              label-for="kategori"
            >
              <v-select
                v-model="rowData.data.kategori_jenis_keterampilan"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="kategoriKeterampilanOptions"
                :reduce="val => val.value"
                :clearable="false"
              />
            </b-form-group>

          </b-col>
        </b-row>

         <b-row>
          <b-col
            xl="8"
          >
            <b-form-group
              label="Keterampilan"
              label-for="keterampilan"
            >
              <b-form-input
              id="nama"
              v-model="rowData.data.nama"
              autofocus
              trim
              placeholder=""
            />
            </b-form-group>

          </b-col>
        </b-row>

        <!-- <b-row>
          <b-col
            xl="6"
          >
            <b-form-group
              label="Deskripsi FAQ"
              label-for="nama"
            >
              <b-form-textarea
                id="deskripsi"
                v-model="rowData.data.deskripsi"
                autofocus
                trim
                placeholder=""
              />
            </b-form-group>

          </b-col>
        </b-row> -->

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            @click="updateData"
          >
            Simpan
          </b-button>
        </div>

      </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge,
  BForm, BFormGroup, BFormInvalidFeedback, // BFormTextarea,  BFormInput
  BFormSpinbutton, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { required, alphaNum, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { avatarText } from '@core/utils/filter'
import useList from '@/connection-api/keterampilan/list'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    // BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          altFormat: 'M j, Y',
          altInput: true,
          dateFormat: 'Y-m-d',
        },
      },
    }
  },
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    kategoriKeterampilanOptions: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    // this.userData = this.rowData.data
    // console.log(this.userData)
  },
  setup() {
    const { resolveUserRoleVariant, resolveUserStatusVariant } = useList()
    const blankUserData = {
      // nama: this.rowData.data.nama,
      // wilayah: this.rowData.data.wilayah,
      // upt: this.rowData.data.upt,
      // jenis_asset_id: this.rowData.data.jenis_asset_id,
      // status_asset_id: this.rowData.data.status_asset_id,
      // tanggal_aktif: this.rowData.data.tanggal_aktif,
      // luas_tanah: this.rowData.data.luas_tanah,
      // luas_bangunan: this.rowData.data.luas_bangunan,
      // harga_perolehan: this.rowData.data.harga_perolehan,
      // id: this.rowdata.data.id,
    }
    // alert(this.userData.nama)
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))

    const onSubmit = () => {
      store.dispatch('keterampilan/updateData', this.rowData.data)
        .then(response => {
        })
        .catch(error => {
          // console.log
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      avatarText,
      resolveUserRoleVariant,
      resolveUserStatusVariant,

      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    async updateData() {
      // console.log(this.rowData.data)
      store.dispatch('keterampilan/updateData', this.rowData.data)
        .then(response => {
          this.makeToast('success', 'Simpan Data Keterampilan Berhasil Dilakukan')
        })
        .catch(error => {
          // console.log(error)
        })
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatDate(value, format) {
      return moment(String(value)).format(format)
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        // title: `Variant ${variant || 'default'}`,
        title: 'Keterampilan',
        variant,
        solid: true,
        autoHideDelay: 10000,
      })
    },
  },
}
</script>

<style>

</style>
